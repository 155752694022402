const smartFeaturesData = {
  popularItems: {
    Wedding: {
      title: "Popular Wedding add-ons",
      description:
        "What other wedding clients have added for cocktail hour or their taco bar",
      minimum: 0,
      offerOnDays: [0, 1, 2, 3, 4, 5, 6],
      isActive: true,
      addOns: [
        {
          id: 1003,
          name: "Appetizers",
          description: "All prices are per guest",
          quantityStep: 1,
          initialQuantity: 1,
          items: [
            {
              id: 600,
              name: "Chunky Guacamole & Chips (appetizer)",
              price: 3.25,
              note: "* includes plates, napkins and utensils",
            },
            { id: 601, name: "Elotes - Mexican-style Corn", price: 3.5 },
            { id: 602, name: "Nacho Bar", price: 4.0 },
            { id: 603, name: "Mexican Shrimp Cocktail Shooter", price: 4.0 },
            { id: 604, name: "Charcuterie Boards", price: 0 },
            { id: 605, name: "Artisan Quesadillas", price: 0 },
          ],
        },
        {
          id: 1004,
          name: "Drinks",
          description:
            "All prices are per 3.0 gallons. Cups included. Serves approx. 40 guests.",
          quantityStep: 1,
          initialQuantity: 1,
          items: [
            {
              id: 200,
              name: "Horchata",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
            {
              id: 201,
              name: "Jamaica",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
            {
              id: 202,
              name: "Watermelon",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
            {
              id: 203,
              name: "Lemonade",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
            {
              id: 204,
              name: "Water Station - Cucumber or Lemon slices",
              price: 35.0,
              note: "* Serves approx. 40 guests.",
            },
          ],
        },
        {
          id: 1005,
          name: "Desserts",
          description: "",
          quantityStep: 1,
          initialQuantity: 1,
          items: [
            {
              id: 700,
              name: "Churros (On-site)",
              price: 0,
              note: "* Made Fresh On Site. Includes - Chocolate & Caramel Syrup and Ice Cream",
            },
            { id: 701, name: "Mixed Fruit Buffet", price: 3.75 },
          ],
        },
      ],
    },
    default: {
      title: "Popular add-ons",
      description:
        "What other clients have added for cocktail hour or their taco bar",
      minimum: 0,
      offerOnDays: [0, 1, 2, 3, 4, 5, 6],
      isActive: true,
      addOns: [
        {
          id: 1003,
          name: "Appetizers",
          description: "All prices are per guest",
          quantityStep: 1,
          initialQuantity: 1,
          items: [
            { id: 601, name: "Elotes - Mexican-style Corn", price: 3.5 },
            { id: 602, name: "Nacho Bar", price: 4.0 },
            { id: 603, name: "Mexican Shrimp Cocktail Shooter", price: 4.0 },
          ],
        },
        {
          id: 1004,
          name: "Drinks",
          description:
            "All prices are per 3.0 gallons. Cups included. Serves approx. 40 guests.",
          quantityStep: 1,
          initialQuantity: 1,
          items: [
            {
              id: 200,
              name: "Horchata",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
            {
              id: 201,
              name: "Jamaica",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
            {
              id: 202,
              name: "Watermelon",
              price: 65.0,
              note: "* Serves approx. 40 guests.",
            },
          ],
        },
        {
          id: 1005,
          name: "Desserts",
          description: "",
          quantityStep: 1,
          initialQuantity: 5,
          items: [
            {
              id: 700,
              name: "Churros (On-site)",
              price: 0,
              note: "* Made Fresh On Site. Includes - Chocolate & Caramel Syrup and Ice Cream",
            },
            { id: 701, name: "Mixed Fruit Buffet", price: 3.75 },
          ],
        },
      ],
    },
  },
};

export default smartFeaturesData;
