import Vue from "vue";
import Vuex from "vuex";
import QuoteService from "@/services/QuoteService";
import { isFridayOrSaturday } from "@/utils/sharedUtils";

import menuData from "./menu-data";
import smartFeaturesData from "./smart-features-data";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentStep: 0,
    maxSteps: 6,
    showWhiteGloveModal: false,
    showChafersModal: false,
    changeStepAllowed: true,
    customerData: {
      // name:"Vladislav",
      // lastName:"Stankovic",
      // email:"vlad@testingtest23aaa.com",
      // phone:"123453",
      // city:"New York",
      // date:"2022-06-28",
      // time:"19:57",
      // hours: "01",
      // minutes: "00",
      // amPm: "am",
      // occasion: 'Birthday',
      // additionalMessage: 'Some sample message',
      name: "",
      lastName: "",
      email: "",
      phone: "",
      city: "",
      date: "",
      time: "",
      hours: "",
      minutes: "",
      amPm: "",
      occasion: "",
      additionalMessage: "",
      contactMe: "",
      complimentaryChaffers: false,
      specialOffers: undefined,
    },
    menuDataObj: {},
    smartFeaturesDataObj: {},
    numberOfGuests: 15,
    salesTax: 0.0875,
    minGuestsReceivingChaffers: 25,
    minGuests: 10,
    maxGuests: 700,
    minQuantity: 1,
    maxQuantity: 100,
    onsiteMinimumAmount: 1000,
    onsiteLowerMinimumAmount: 800,
    deliveryMinimum: 175,
    minimumMet: false,
    isFridayOrSaturday: false,
    defaultQuantityStep: 5,
    defaultInitialQuantity: 1,
    selectedMenu: 0,
    selectedDelivery: 0,
    selectedAddons: {},
    selectedProteins: [],
    salesTaxTotal: 0,
    menuCostSum: 0,
    totalCost: 0,
  },
  mutations: {
    SET_MENU_DATA(state, data) {
      state.menuDataObj = data;
      state.selectedAddons = data.addOns;
    },
    SET_SMART_FEATURES_DATA(state, data) {
      state.smartFeaturesDataObj = data;
    },
    SET_INITIAL_STATE(state, addOnsObj) {
      state.selectedMenu = 0;
      state.selectedDelivery = 0;
      state.selectedAddons = addOnsObj;
    },
    SET_NUMBER_OF_GUESTS(state, data) {
      state.numberOfGuests = data;
    },
    SET_DELIVERY_OPTION(state, data) {
      state.selectedDelivery = data;
    },
    SET_SELECTED_MENU(state, data) {
      state.selectedMenu = data;
    },
    SEND_QUOTE(state, data) {
      QuoteService.sendQuoteRequest(data);
    },
    ADD_SELECTED_ADDON(state, addonData) {
      state.selectedAddons[`${addonData.groupId}`][`${addonData.itemId}`] =
        addonData;
    },
    REMOVE_ADDON(state, addonData) {
      delete state.selectedAddons[`${addonData.groupId}`][
        `${addonData.itemId}`
      ];
    },
    UPDATE_ITEM_QUANTITY(state, addonData) {
      state.selectedAddons[`${addonData.groupId}`][
        `${addonData.itemId}`
      ].quantity = addonData.quantity;
    },
    UPDATE_CURRENT_STEP(state, number) {
      state.currentStep = number;
    },
    UPDATE_STEP_CHANGE(state, value) {
      state.changeStepAllowed = value;
    },
    UPDATE_CUSTOMER_DATA(state, customerData) {
      state.customerData = customerData;
    },
    UPDATE_EVENT_DATE(state, date) {
      state.customerData.date = date;
      state.isFridayOrSaturday = isFridayOrSaturday(date);
    },
    SET_CONTACT_ME(state, value) {
      state.customerData.contactMe = value;
    },
    SET_COMPL_CHAFFERS(state, value) {
      state.customerData.complimentaryChaffers = value;
    },
    UPDATE_PROTEINS_LIST(state, proteinsData) {
      state.selectedProteins = proteinsData;
    },
    UPDATE_SPECIAL_OFFERS(state, specialOffersData) {
      state.customerData.specialOffers = specialOffersData;
    },
    ADD_SELECTED_PROTEIN(state, item) {
      const newProteinList = state.selectedProteins.push(item);
      state.selectedProteins = newProteinList;
    },
    REMOVE_SELECTED_PROTEIN(state, item) {
      const index = state.selectedProteins.indexOf(item);
      if (index < 0) {
        return;
      }
      state.selectedProteins.splice(index, 1);
    },
    REMOVE_FIRST_PROTEIN(state) {
      const newProteinList = state.selectedProteins.shift();
      state.selectedProteins = newProteinList;
    },
    SET_MENU_COST_SUM(state, number) {
      // menu cost without tax
      state.menuCostSum = number;
    },
    SET_TOTAL_COST(state, number) {
      state.totalCost = number;
    },
    SET_SALES_TAX_TOTAL(state, number) {
      state.salesTaxTotal = number;
    },
    SET_MINIMUM_MET(state, value) {
      state.minimumMet = value;
    },
    TOGGLE_WHITE_GLOVE_MODAL(state, value) {
      state.showWhiteGloveModal = value;
    },
    TOGGLE_CHAFERS_MODAL(state, value) {
      state.showChafersModal = value;
    },
  },
  actions: {
    setMenuData({ commit }) {
      commit("SET_MENU_DATA", menuData);
      commit("SET_SMART_FEATURES_DATA", smartFeaturesData);
      Promise.resolve(menuData).then((res) => {
        const addOns = res.addOns;
        const tempAddOns = {};
        addOns.forEach((element) => {
          tempAddOns[`${element.id}`] = {};
        });
        commit("SET_INITIAL_STATE", tempAddOns);
      });
    },
    setNumberOfGuests({ commit }, number) {
      commit("SET_NUMBER_OF_GUESTS", number);
    },
    setDeliveryOption({ commit }, number) {
      commit("SET_DELIVERY_OPTION", number);
    },
    setSelectedMenu({ commit }, number) {
      commit("SET_SELECTED_MENU", number);
    },
    addSelectedAddon({ commit }, addonData) {
      commit("ADD_SELECTED_ADDON", addonData);
    },
    removeAddon({ commit }, addonData) {
      commit("REMOVE_ADDON", addonData);
    },
    updateItemQuantity({ commit }, addonQuantityData) {
      commit("UPDATE_ITEM_QUANTITY", addonQuantityData);
    },
    setNewStep({ commit }, number) {
      commit("UPDATE_CURRENT_STEP", number);
    },
    setStepChange({ commit }, value) {
      commit("UPDATE_STEP_CHANGE", value);
    },
    setCustomerData({ commit }, customerData) {
      commit("UPDATE_CUSTOMER_DATA", customerData);
    },
    setEventDate({ commit }, date) {
      commit("UPDATE_EVENT_DATE", date);
    },
    removeFirstProteinItem({ commit }) {
      commit("REMOVE_FIRST_PROTEIN");
    },
    updateProteinsList({ commit }, data) {
      commit("UPDATE_PROTEINS_LIST", data);
    },
    removeSelectedProteinItem({ commit }, item) {
      commit("REMOVE_SELECTED_PROTEIN", item);
    },
    updateSpecialOffers({ commit }, offersData) {
      commit("UPDATE_SPECIAL_OFFERS", offersData);
    },
    addSelectedProteinItem({ commit }, item) {
      commit("ADD_SELECTED_PROTEIN", item);
    },
    setMenuCostSum({ commit }, number) {
      // setting menu cost without tax
      commit("SET_MENU_COST_SUM", number);
    },
    setQuoteTotal({ commit }, number) {
      commit("SET_TOTAL_COST", number);
    },
    setMinimumMet({ commit }, value) {
      commit("SET_MINIMUM_MET", value);
    },
    setSalesTaxTotal({ commit }, number) {
      commit("SET_SALES_TAX_TOTAL", number);
    },
    setContactMe({ commit }, value) {
      commit("SET_CONTACT_ME", value);
    },
    setComplimentaryChaffers({ commit }, value) {
      commit("SET_COMPL_CHAFFERS", value);
    },
    sendQuote({ commit }, data) {
      console.log("Sending the calculated stuff", data);
      commit("SEND_QUOTE", data);
    },
    calculateTotal({ dispatch }) {
      const categoryIds = Object.keys(this.state.selectedAddons);
      let menuCostSum =
        this.state.numberOfGuests *
        this.state.menuDataObj.menus[this.state.selectedMenu].deliveryOption[
          this.state.selectedDelivery
        ].price;

      categoryIds.forEach((element) => {
        if (Object.keys(this.state.selectedAddons[element]).length > 0) {
          const itemIds = Object.keys(this.state.selectedAddons[element]);

          itemIds.forEach((item) => {
            const tempItem = this.state.selectedAddons[element][item];
            menuCostSum += tempItem.price * tempItem.quantity;
          });
        }
      });

      const salesTaxAmount =
        parseFloat(menuCostSum.toFixed(2)) * this.state.salesTax;
      const total = menuCostSum + salesTaxAmount;

      dispatch("setSalesTaxTotal", parseFloat(salesTaxAmount.toFixed(2)));
      dispatch("setQuoteTotal", parseFloat(total.toFixed(2)));
      dispatch(
        "setMinimumMet",
        parseFloat(total.toFixed(2)) > this.state.onsiteMinimumAmount
      );
      dispatch("setMenuCostSum", parseFloat(menuCostSum.toFixed(2)));
    },
    toggleWhiteGloveModal({ commit }, data) {
      commit("TOGGLE_WHITE_GLOVE_MODAL", data);
    },
    toggleChafersModal({ commit }, data) {
      commit("TOGGLE_CHAFERS_MODAL", data);
    },
  },
  getters: {
    selectedAddons: (state) => (selectedAddons) => {
      if (!selectedAddons) return "";
      else {
        console.log(state.selectedAddons);
      }
    },
  },
  modules: {},
});
