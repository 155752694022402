<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Hotjar from '@hotjar/browser';

const siteId = process.env.VUE_APP_HOTJAR_ID;
const hotjarVersion = 6;

export default {

  
  name: "App",
  mounted() {
    this.setMenuData();
    if (siteId) {
      Hotjar.init(siteId, hotjarVersion);
    }
  },
  methods: {
    setMenuData() {
      this.$store.dispatch("setMenuData");
    },
  },
};
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");

@mixin gradient-bg {
  background-color: #738bdc;
  background-image: linear-gradient(315deg, #738bdc 0%, #48c3eb 74%);
}

.bg-gradient {
  @include gradient-bg;
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

hr {
  border-top: 1px solid #e1e1e1;
}
</style>
