import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './mixins/titleMixin'

import 'spectre.css/dist/spectre-exp.css';
import 'spectre.css/dist/spectre-icons.css';
import 'spectre.css/dist/spectre.css';
import { VectrePlugin } from '@vectrejs/vectre';

export const eventBus = new Vue(); // creating an event bus.
Vue.config.productionTip = false;
Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  VectrePlugin,
  render: h => h(App)
}).$mount('#app')
