export default {
    getToken() {
        let token = window.localStorage.getItem('token');
        return token;
      },

    setToken(token) {
      window.localStorage.token = token;
      return;
    },

    setCurrentUser(user) {
      window.localStorage.currentUser = JSON.stringify(user);
      return;
    },

    getCurrentUser() {
      if (window.localStorage.currentUser) {
        let user = JSON.parse(window.localStorage.currentUser);
        return user;
      } else {
        return {};
      }
    },


    logout() {
      window.localStorage.removeItem("currentUser");
      window.localStorage.removeItem("token");
      window.location = "/"
    }
}