import axios from 'axios'
import LocalStorageService from './LocalStorageService'

export default () => {
    let config = {
      baseURL: process.env.API_URL 
    };
  
    let instance = axios.create(config);
    const token = LocalStorageService.getToken()
    
    if (token) {
      instance.defaults.headers.common['Authorization'] = "token " + token;
    }
  
    return instance;
  };
  